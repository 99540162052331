:root {
    --sponsor-path: var(--sponsor-path, 'admin');
    --asset-prefix: var(--asset-prefix, '/');
}
$base-font: 'Open Sans', sans-serif;
$secondary-font: 'Poppins', sans-serif;

$primary-color: #0F1457;
$secondary-color: #00ABC8;
$info-color: #00ABC8;
$tertiary-color: #7044ff;
$success-color: #70D44B;
$warning-color: #FFC600;
$danger-color: #f04141;
$dark-color: #313E48;
$medium-color: #747474;
$light-color: #FFFFFF;
$light-gray-color: #C8C8C8;
$logo-color: #867B78;

$login-page-bg-color: #B7C0C7;
$login-form-bg-color: #F6F6F6;
$content-bg-color: $login-form-bg-color;

$table-header: #303E48;
$border: #CECECE;

$spinner-width: 2rem;
$spinner-height: 2rem;

$spinner-border-width: .25rem;

$spinner-width-sm: 1rem;
$spinner-height-sm: 1rem;
$spinner-border-width-sm: .2rem;

$assetPrefix: var(--asset-prefix);