// @media (min-width: 720px){
.checkbox-outline {
  outline: 2px solid #00ABC8;
}

.login-page {
  .login_form {
    width: 100%;
    max-width: 400px;
  }

  .bg_login {
    position: absolute;
    height: 100vh;
  }

  .container-fluid>.row>div:first-child {
    height: 5em;
  }

  .login_footer.visible-xs {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .form_container {
    height: calc(100vh - 5em);
  }
}

.text-light {

  p,
  span,
  label,
  a {
    color: $light-color !important;
  }

  color: $light-color !important;
}

.btn-light {
  color: $primary-color;
  background-color: $light-color;
}

.table-responsive {
  overflow: visible;
}

.disabled {
  cursor: not-allowed;
  color: #ddd;
}

.page-link {
  cursor: pointer;
}

.alert-modal {
  &.backdrop {
    z-index: 3000;
  }

  &.modal {
    z-index: 3001;
  }
}

.chnge-listing {
  display: flex;
  flex-direction: column;
}

.suc_bg {
  padding: 2em 2em !important;
  text-align: center;
  color: white;

  svg {
    width: 100% !important;
    margin: auto !important;
  }
}

.logo-img {
  max-height: 70px;
  max-width: 230px;
  width: 60%;
}

.error-message {
  color: #dd4b39;
}

// }

.content-wrapper {
  min-height: auto !important;

  .content {
    min-height: calc(100vh - (70px + 56.67px)) !important;
  }

  .content-header+.content {
    min-height: calc(100vh - (70px + 101.33px + 56.67px)) !important;
  }

  .code-content-bg,
  .code-bg {
    height: calc(100vh - (70px + 56.67px)) !important;
    min-height: calc(100vh - (70px + 56.67px)) !important;
  }

  .content-header+.content {
    min-height: calc(100vh - (70px + 101.33px + 56.67px)) !important;
  }
}

.content-wrapper_landing {
  min-height: auto !important;

  .content {
    min-height: calc(100vh - (70px + 56.67px)) !important;
  }

  .content_ {
    min-height: 100vh !important;
  }

  .code-content-bg,
  .code-bg {
    height: 100vh  !important;
    min-height: 100vh !important;
  }

  .content-header+.content {
    min-height: calc(100vh - (70px + 94.67px + 56.67px)) !important;
  }
}

.card-wrapper,
.copay-card-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.card-wrapper>.logo {
  min-height: 75px !important;
  height: 75px !important;
  margin: 13px 0 0 13px;
}

.copaycard>img {
  min-height: 75px !important;
  height: 75px !important;
  margin: 13px 0 0 13px !important;
  width: auto;
}

.copay-card-wrapper>img {
  min-height: 75px !important;
  height: 75px !important;
  margin: 13px 0 0 13px !important;
  padding: 0 !important;
  width: auto !important;
}

.card-wrapper .cardholder-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
  overflow: hidden;
}

.card-wrapper,
.copay-card-wrapper {
  margin: auto;
  height: 2.75in;
  width: 4.5in;

  .card-details {
    margin: 10px 0 0 20px !important;
  }

  .card-footer-content {
    margin-top: 50px;
  }
}

.copay-card-wrapper {
  display: inline-block;

  .details {
    padding-top: 0 !important;
    margin-top: -5px !important;
    font-size: 14px !important;

    .amount-details .amount span {
      font-size: 5.5rem !important;
    }

    .amount-details .description {
      font-size: 12px !important;
    }
  }

  .copay-card-footer {
    margin-top: -8px !important;

    .content-card-footer {
      padding: 10px 16px !important;
    }
  }
}

.ymabscard {
  display: flex;
  align-items: center;
  background-image: none !important;
  overflow: hidden;

  .flex-column {
    flex-direction: column;
  }

  .card-details {
    display: flex;
    margin-left: 0 !important;
    font-weight: 400 !important;

    p {
      font-weight: 600 !important;
    }

    .card-currency {
      margin: 8px 0;
    }

    .card-currency,
    .card-amount {
      color: #4e4e4e;
    }
  }

  img {
    position: absolute;
    height: 2.75in !important;
    width: 4.5in !important;
    margin: 0 !important;
    top: 0;
    left: 0;
  }
}

.profile-box {
  padding: 0 20px;
}

.d-flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.p_member_id,
.p_first_name,
.p_last_name {
  font-weight: bold;
}

.error-message {
  display: none;
  margin-bottom: 1em;
}

.has-error+.error-message {
  display: inline-block;
}

.form-control:not(.csearch-inpt):not(.info-c) {
  border: 1px solid #f6f6f6 !important;
}

.has-error .form-control {
  border-color: #dd4b39 !important;

  &:not(.csearch-inpt):not(.info-c) {
    border-color: #dd4b39 !important;
  }
}

.has-error>.custom-select>div {
  border-color: #dd4b39 !important;
}

.content_enrollment {
  min-height: 400px;
}

.content-listing .custom-checkbox-container {
  display: inline-block;
}

input.info-c {
  background-color: transparent !important;
  border: none !important;
  pointer-events: none;
  padding: 0;
  height: 1em;
}

.info-c {
  min-height: 1em;
}

.pagination .active {
  color: $secondary-color;
}

.treeview .pull-right-container {
  cursor: pointer;
}

.sidebar-menu>li>a {
  a {
    margin: -17px -15px -15px -15px;
    padding: 17px 15px 17px 15px;
    display: block;
    width: 100%;
    overflow: hidden;
  }

  .pull-right-container {
    padding: 1em;
    margin-top: calc((1em + 10px) * -1);
    margin-right: -1em;
  }
}

.sidebar-collapse .visible-xs+.pull-right-container {
  visibility: hidden !important;
}

.visible-xs .pull-right-container {
  padding: 0 !important;
}

.search-content svg:not(.fa-user-md) {
  width: 1em !important;
  height: 1em !important;
  margin-bottom: 0 !important;
}

.err_footer {
  cursor: pointer;
}

.main-header .program {
  width: 150px;
}

.main-header .program>img {
  max-height: 40px;
}

#img-upload {
  max-height: 70px;
}

.custom-select {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.status {
  font-weight: bolder;
  text-transform: uppercase;

  &.pending,
  &.payment_pending,
  &.ocr_processing,
  &.approval,
  &.for_approval,
  &.approval_1,
  &.\31st_approval,
  &.approval_2,
  &.\32nd_approval {
    color: $warning-color;
  }

  &.active,
  &.valid,
  &.created,
  &.approved,
  &.incoming,
  &.processed,
  &.payment_processed {
    color: $success-color;
  }

  &.inactive,
  &.invalid,
  &.failed,
  &.payment_failed {
    color: $danger-color;
  }

  &.rejected,
  &.outgoing {
    color: $danger-color;
  }
}

.text-danger {
  color: $danger-color !important;
}

.text-wrap { 
  white-space: normal !important;
}

.table-responsive {
  overflow-x: auto;
}

.pg-viewer {
  max-height: 600px;
}

.radio-custom,
.react-grid-checkbox {
  opacity: 1 !important;
  position: relative !important;
}

.disabled,
[disabled] {
  pointer-events: none;
}

.info-label {
  color: #747474;
}

.info-c {
  color: #3f3f3f;
}

.w150px {
  width: 150px;
}

.marked-required {
  color: $danger-color;
}

.nowrap {
  white-space: nowrap;
}

.accord-height {
  overflow: visible !important;

  >.row {
    // width: fit-content;
  }
}

.service-width {
  // overflow: visible !important;
}

#serviceInformationTable {
  .search-inpt {
    font-weight: normal !important;
  }
}

.scrollable {
  overflow: auto !important;

  &::-webkit-scrollbar {
    width: 3px;
    height: 10px;
  }

  /* track */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border: 1px solid #eee;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: #00abc8;
    /*    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background-color: #d9d9d9;
  }
}

.invalid-container {
  width: 100%;
}

.invalid-content {
  h1 {
    text-align: center;
  }

  .sign-invalid {
    float: none;
    margin: auto;
  }

  .col-lg-3.col-md-3.col-xs-12 {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .invalid-content {
    h1 {
      text-align: left;
    }
  }

  .sign-invalid {
    float: right;
  }

  .invalid-container {
    width: 50%;
  }
}

@media (max-width: 767px) {

  .main-header .logo,
  .main-header .navbar,
  .main-header .navbar_reserve {
    background-color: #001f60 !important;
  }
}

/* implement navbar scroll */

.main-sidebar {
  max-height: calc(100vh - (56px + 70px));
  height: calc(100vh - (56px + 70px));
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  position: relative;

  &::-webkit-scrollbar {
    width: 3px;
    height: 10px;
  }

  /* track */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border: 1px solid #eee;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: #00abc8;
    /*    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background-color: #d9d9d9;
  }
}

.sidebar-collapse {
  .main-sidebar {
    overflow: visible;
  }
}

.content-wrapper {
  position: absolute;
  top: 70px;
  width: calc(100% - 230px);
  max-height: calc(100% - (57px + 70px));
  height: calc(100% - (57px + 70px));
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
    height: 10px;
  }

  /* track */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border: 1px solid #eee;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: #00abc8;
    /*    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background-color: #d9d9d9;
  }
}

.sidebar-collapse {
  .content-wrapper {
    width: calc(100% - 70px);
    transition: none;
  }
}

.main-footer {
  margin-left: 0;
}

body {
  overflow: hidden;
  /* 
  visibility: hidden;
  &.skin-darkgreen{
    visibility: visible;
  } 
  */
}

/* end of sidebar scroll */
.steps ul:after {
  width: calc(100% - 106px);
  left: 6px;
  transform: none;
}

.ymabs {
  .steps ul:after {
    bottom: 30%;
  }
}

.loader {
  transform: none;
  margin: auto;
}

.ReactCrop {
  width: 100% !important;

  .ReactCrop__image {
    width: 100% !important;
  }
}

.filepond--root {
  font-size: 1.25em !important;
}

.mini-bg-dp2 {
  border: 1px solid #eee;
}

.flex-start {
  justify-content: flex-start;
}

@import "datepicker";

.content-only {
  &.wrapper {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }

  &.content-wrapper {
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0;
    top: 0;
  }
}

.row-flex {
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
}

.custom-checkbox-toggle {
  width: 14px;
  height: 14px;
  background: #fff;
  border: 1px solid;
  top: calc((14px / 2) - 1px);
  border-radius: 50%;
  position: relative;
}

.custom-checkbox {
  display: none;

  &:checked {
    +.custom-checkbox-toggle {
      border-color: $secondary-color;

      .dot {
        width: 10px;
        height: 10px;
        position: absolute;
        border-radius: 50%;
        background: $secondary-color;
        left: 1px;
        top: 1px;
      }
    }
  }
}

.bg-secondary {
  background-color: $secondary-color;
}

.bg-success {
  background-color: $success-color;
}

.bg-warning {
  background-color: $warning-color;
}

.bg-danger {
  background-color: $danger-color;
}

.bg-dark {
  background-color: $dark-color;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-success {
  color: $success-color !important;
}

.text-warning {
  color: $warning-color !important;
}

.text-danger {
  color: $danger-color !important;
}

.text-dark {
  color: $dark-color !important;
}

.module_program h1 {
  font-size: 22px;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.style-list {
  display: flex;
  list-style: none;
  flex-direction: column;
  width: 300px;
  justify-content: center;
  padding: 0;
  margin: 0;
  margin-top: -1em;

  >li {
    &:first-child {
      font-size: 1.5em;
    }

    display: flex;
    justify-content: space-between;
    line-height: 2;
  }
}

.main-header .navbar_reserve .sidebar_return:hover {
  background: #1697ad;
}

.profile-box .info-c {
  white-space: pre-wrap;
  word-break: break-all;
}

.list_view {
  width: 100%;

  .box-body>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .box-body .row {
    display: flex;
  }
}

.table-head h3 {
  font-weight: bold;
  margin: 5px 0px;
  font-size: 17px;
}

.table-fixed {
  table-layout: fixed;
}

/** provider verification **/
.verifcation-wrapper {
  width: 70%;
  margin: 0 auto;
}

.verifcation-wrapper {
  width: 50%;
  margin: 0 auto;
}

.verifcation-container img {
  width: 100%;
}

.verifcation-content h1 {
  margin: 0 0 20px 0;
  color: #0f1457;
  font-weight: bolder;
  font-size: 38px;
  text-align: left;
}

.verifcation-content h4 {
  margin: 0 0 5px 0;
  color: #0f1457;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}

.verifcation-content-message {
  padding-top: 50px;
}

.verifcation-content {
  margin: 70px 20px;
  text-align: left;
}

.verifcation-content p {
  color: #747474;
  font-size: 18px;
  margin-bottom: 50px;
  line-height: 30px;
  width: 80%;
}

.verifcation-ic-border {
  height: 200px;
  width: 200px;
  background-color: transparent;
  border-radius: 50%;
  text-align: center;
  line-height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verifcation-ic-border.success-border {
  border: 6px solid #5dc833;
}

.verifcation-ic-border .success {
  color: #5dc833;
}

.verifcation-ic-border.error-border {
  border: 6px solid #e02020;
}

.verifcation-ic-border .error {
  color: #e02020;
}

.verifcation-ic-border svg {
  font-size: 80px;
}

.tab-header {
  font-weight: bold;
  padding-left: 20px;
  font-size: 17px;
  margin: 0;
}

.sidebar {
  .sidebar-menu {
    a>a {
      display: flex;
      white-space: pre-wrap;
      align-items: center;
    }
  }
}

.program-unavailable {
  display: inherit;
  font-size: 10px;
  background-color: #001f60;
  width: auto;
  padding: 2px 10px;
  border-radius: 15px;
  color: #fff;
}

.disable-row {
  background-color: #e9e9e9 !important;
  pointer-events: none;
  width: 100%;
}

.tooltip-container {
  cursor: pointer;
  position: relative;
  display: inline-block;

  .tooltip {
    opacity: 0;
    z-index: 999;
    color: #dd4b39;
    width: 250px;
    display: block;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;
    /* background: rgba(51,51,51,0.9);*/
    background-color: #fff;
    border: 1px solid #dd4b39;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    position: absolute;
    right: 20px;
    bottom: 55px;
    right: -33%;
    right: 0;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    min-height: fit-content;
  }

  .tooltip:before,
  .tooltip:after {
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #dd4b39;
    position: absolute;
    bottom: -10px;
    left: 50%;
  }

  &.has-error .form-control {
    border: 1px solid #dd4b39 !important;
  }

  &.has-error.row-flex {
    border: 1px solid #dd4b39 !important;
  }

  &.has-error>.row {
    border: 1px solid #dd4b39 !important;
  }

  &.has-error>.custom-checkbox-container {
    border: 1px solid #dd4b39 !important;
  }
}

.tooltip-container:hover .tooltip,
.tooltip-container a:hover .tooltip {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.ruleset-error {
  margin: 5px 0px;
  padding: 0px;
}

.ruleset-error h3 {
  margin: 10px 0;
  line-height: 1.12857143;
  font-weight: bold;
  font-family: "Open Sans";
  font-size: 16px;
  color: #dd4b39;
}

.ruleset-error .list-error {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px 40px;
  margin: 10px 0px;
  max-height: 150px;
  overflow-y: auto;
}

.ruleset-error .list-error ul {
  padding: 0px;
  margin: 0px;
}

.ruleset-error .list-error ul li {
  padding: 5px 0px;
  margin: 0px;
  color: #dd4b39;
  font-size: 12px;
}

.indent-marg20 {
  margin-left: 20px;
}

.indent-marg40 {
  margin-left: 40px;
}

.card-details {
  p {
    color: var(--card-fg-color, #313e48) !important;
  }
}

.w150px {
  width: 150px;
}

.w200px {
  width: 200px;
}

#serviceInformationTable,
.patient-invoice,
.patient-dtpp {
  .tooltip-container .tooltip {
    top: -55px;
  }
}

.patient-invoice,
.patient-dtpp {
  .has-error>input {
    border-color: #dd4b39 !important;
  }

  .tooltip-container .tooltip {
    left: -120px;
  }
}

.status-float {
  position: absolute;
  right: 20px;
  top: 10px;

  .status {
    text-transform: uppercase !important;
    font-weight: bold !important;
  }
}

.total-amount {
  color: #00abc8 !important;
  font-size: 28px !important;
  margin: 0px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.tooltip-right,
.tooltip-left {
  position: relative;
  display: inline-block;
  float: right;
}

.tooltip-right,
.tooltip-left {
  >svg {
    color: #b3b2b2;
  }
}

.tooltip-right:hover .tooltiptext,
.tooltip-left:hover .tooltiptext {
  visibility: visible;
}

.tooltip-left .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.04);
  color: #6e6e6e !important;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #c7c6c6;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  line-height: 16px;
  right: 10px;
}

.tooltip-right .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.04);
  color: #6e6e6e !important;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #c7c6c6;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  line-height: 16px;
}

.tooltiptext,
.tooltip-left,
.tooltip-right {
  display: none !important;
}

[role="dialog"]+[role="dialog"] {
  z-index: 2000;

  .modal-backdrop {
    z-index: 2000;
  }
}

.filepond--credits {
  display: none !important;
}

.err ul {
  list-style-type: none;
  text-align: left;
  padding: 5px;
}

.err svg {
  color: var(--fg-danger-color, #f65656);
}

.err_footer {
  background-color: var(--bg-danger-color, #f65656);
}

.status-leger {
  float: left;
  padding: 0px;
  margin: 0px;
}

.status-leger li {
  padding: 0px 5px;
  font-size: 10px;
  list-style: none;
  display: inline-flex;
  line-height: 20px;
  margin-right: 3px;
}

.status-leger li svg {
  padding: 5px;
  text-align: center;
  font-size: 10px;
  color: #fff;
  width: 2em !important;
  height: 2em;
  margin-right: 3px;
}

.available {
  background-color: #0ccb66;
}

.performance {
  background-color: #cb0e5c;
}

.disruption {
  background-color: #e7bf13;
}

.maintenance {
  background-color: #b0b0b0;
}

.status-leger-list {
  padding: 5px;
  text-align: center;
  font-size: 10px;
  color: #fff;
  width: 2em !important;
  height: 2em;
  margin-right: 3px;
}

.skin-darkgreen .sidebar-menu>li>a .svg-inline--fa {
  min-width: 20px;
}

.skin-darkgreen .sidebar-menu>li>a>a>.svg-inline--fa {
  margin-left: 4px;
}

.favbtn {
  background: transparent;
  border: none;
  // position: relative;
  // top: -2.5rem;
  // right: -1rem;

  position: absolute;
  top: 0;
  right: 0;

  &:hover,
  &:active,
  &.active {
    color: var(--bg-danger-color, #dd4b39);
  }
}

.favorites {
  padding: 0px 0px;
}

.favorites-sidebar {
  padding: 20px;
}

.favorites h1 {
  margin: 0px 0px 15px 0px;
  padding: 0px;
  color: #636569;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
}

.favorites ul {
  padding: 0px;
  margin: 10px 0px;
  float: left;
  height: 650px;
  overflow: auto;
}

.favorites ul li {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.04);
  border: 1px solid #eee;
  position: relative;
  border-radius: 8px;
  background: #f6f7fc;
  margin-bottom: 15px;
  padding: 15px 10px;
  width: 46%;
  list-style: none;
  float: left;
  margin-right: 10px;
}

.favorites ul li:hover {
  cursor: pointer;
  border: 1px solid rgba(0, 171, 198, 1);
  box-shadow: 0 5px 5px rgba(5, 171, 191, 0.08);
}

.favorites ul li h1 {
  margin: 0px;
  padding: 0px;
  color: #00abc8;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}

.favorites ul li p {
  font-size: 75%;
  line-height: 13px;
}

.favorites ul li svg {
  font-size: 20px;
  width: 20% !important;
  color: #00abc8;
  margin-bottom: 10px;
}

.favoritesbtn {
  display: grid;
  text-align: center;
}

.favoritesbtn a {
  font-size: 30px;
}

.favoritesbtn small {
  font-size: 9px;
}

.fav-mark {
  color: #fe606f;
}

.right-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin-top: 70px;
  background-color: #fff;
  min-height: 100%;
  width: 0px;
  z-index: 810;
  -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  overflow: auto;
}

.right-sidebar.in {
  transform: translate(0, 0);
  width: 300px !important;
  z-index: 850;
}

.right-sidebar::before {
  content: "";
  width: 100%;
  height: 100%;
}

// Simple tooltip
.trigger-tooltip {
  position: relative;
  display: inline-block;
}

.trigger-tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: rgb(118, 118, 118);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.trigger-tooltip:hover .tooltip-text {
  visibility: visible;
}

// END Simple tooltip

div.MuiDataGrid-cell p.av-status-pending {
  color: orange;
}

div.MuiDataGrid-cell p.av-status-clean {
  color: green;
}

div.MuiDataGrid-cell p.av-status-infected,
div.MuiDataGrid-cell p.av-status-error {
  color: red;
}

// Separator
.separator {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;

  span {
    padding: 0 2rem;
    /* creates the space */
  }

  .line {
    flex: 1;
    height: 1px;
    background-color: #000;
  }
}

// END Separator

.newPass_creat form div.row div.ach-info-select svg {
  margin: 0;
  height: 2em;
  width: 2em !important;
}

.newPass_creat form div.row div.ach-info-select input {
  margin-bottom: 0;
  height: 2em;
}

.mb-5px {
  margin-bottom: 5px;
}

.ml-5px {
  margin-left: 5px;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:hover {
  background-color: transparent !important;
  outline: none !important;
}

.MuiDataGrid-cell .MuiAutocomplete-root .MuiAutocomplete-endAdornment {
  position: absolute;
  right: 0;
  top: calc(50% - 10px) !important;
}

.custom-input-divider {
  position: relative !important;
}

.custom-input-divider::before {
  content: '';
  position: absolute !important;
  top: 50%;
  right: 29px;
  width: 1px;
  height: 60%;
  background-color: grey;
  transform: translateY(-50%);
}

.copay-claims-filters-label {
  width: 75px;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 13px;
  font-weight: bold;
}

.copay-claims-filters-ext-label {
  @extend .copay-claims-filters-label;
  margin-right: 3px;
}

.custom-table-footer {
  padding: 10px;
  font-size: 13px;
  font-weight: 500
}

.MuiTooltip-popper {
  z-index: 9999 !important;
}

.patient-import-remarks-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-container {
  padding: 0 32px;
}

.inserted-link-color a {
  color: #0000FF;
  cursor: pointer;
}

.inserted-link-color a:hover {
  color: #337ab7;
}
form.patient-lookup-form .form-group span.input-error {
  display: none;
}

.pink-background {
  background: #f7b5b5 !important;
}

.patient-list-table, .patient-list-table .MuiDataGrid-root .paginate {
  padding: 1.5rem !important;
}

.programs-list-table, {
  width: 100%;
  padding: 1.5rem;
  box-sizing: border-box;
}

.padding-inline-start-20{
  padding-inline-start:20px;
}

$font-size-lg: 36px;
$font-size-md: 32px;
$font-size-sm: 28px;
$font-size-xs: 24px;

$tagline-font-lg: 18px;
$tagline-font-md: 28px;
$tagline-font-sm: 24px;
$tagline-font-xs: 22px;

@mixin respond-to($breakpoint) {
  @if $breakpoint == 'desktop' {
    @media (max-width: 1200px) { @content; }
  } @else if $breakpoint == 'tablet' {
    @media (max-width: 768px) { @content; }
  } @else if $breakpoint == 'mobile' {
    @media (max-width: 576px) { @content; }
  }
}

.not-supported-device-block {
  .prog-title {
    font-size: $font-size-lg;

    @include respond-to('desktop') {
      font-size: $font-size-lg;
    }

    @include respond-to('tablet') {
      font-size: $font-size-md;
    }

    @include respond-to('mobile') {
      font-size: $font-size-sm;
    }
  }

  .prog-tagline {
    color: #636569;
    font-size: $tagline-font-lg;

    @include respond-to('desktop') {
      font-size: $tagline-font-md;
    }

    @include respond-to('tablet') {
      font-size: $tagline-font-sm;
    }

    @include respond-to('mobile') {
      font-size: $tagline-font-xs;
    }
  }
}
.MuiTooltip-tooltip {
    font-size: 1rem !important;
}

.modal-content-center {
  text-align: center;
}

.modal-content-center ul {
  list-style-position: inside;
  padding: 0;
  display: inline-block; /* Ensures the list is treated like inline content */
}

.modal-content-center p {
  display: inline-block;
  margin: 0;
}

.table-min-width {
  min-width: 2000px;
}

.limited-width-column {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

#CybotCookiebotDialog {
  z-index: 1000 !important;
}

.checkbox-container input:disabled ~ .checkmark {
  background-color: #d5d5d5 !important;
  border-color: #bcbbbb;
  cursor: not-allowed;
}

.word-break {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.separator {
  position: absolute;
  left: 0;
  height: 60%;
  width: 2px;
  background-color: gray !important;
  z-index: 99;
}