.preloader-wrapper {
  height: 100%;
  position: fixed;
  background-color: #eef9fb;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
}

.preloader-wrapper.transparent{
  opacity: 0.4;
}

.loader {
  top: 40%;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: $primary-color;
  font-size: 10px;
  margin: 40px auto;
  position: relative;
  text-indent: -9999em;
  // -webkit-transform: translateZ(0) translateY(-50%);
  // -ms-transform: translateZ(0) translateY(-50%);
  transform: translate(-50%, 0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

