@import "variable";
@import "preloader";
@import "alert";
@import "spinner";
@import "../copay";

table {
  th {
    &.sorting-asc,
    &.sorting-desc,
    &.sorting-undefined,
    &.sorting- {
      padding-left: 2em !important;
    }

    &.sorting-asc svg.fa-sort-up,
    &.sorting-desc svg.fa-sort-down {
      color: $logo-color;
    }
  }
  span {
    // width: 1em;
    // height: 1em;
    position: relative;
    top: 3px;

    svg {
      position: absolute;
      left: -1.2em !important;
      width: 1em;
      height: 1em;
    }
  }
}

.table-head {
  .custom-select.multiple {
    width: 100%;
  }
}
.table.table-bordered.table-hover {
  margin-bottom: 2em;
}
.pagination {
  margin-top: -6px;
}
.custom-select {
  .css-spersy-control {
    height: 40px !important;
  }

  &.multiple {
    height: 40px !important;

    [class*="container"] {
      width: 20px;
      height: 20px;
      display: inline-block;

      & ~ * {
        width: 100%;
        display: inline;
      }
    }
  }
}
// table span{
//     width: 1em;
//     height: 1em;
//     display: inline-block;
// }

.modal {
  background-color: initial;
  z-index: 2000;
}

.custom-switch-button {
  .switch {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 34px;
    margin-top: 2px;
    margin-bottom: 0;
    cursor: pointer;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        &:before {
          -webkit-transform: translateX(66px);
          -ms-transform: translateX(66px);
          transform: translateX(66px);
        }
      }

      &:checked:not(:disabled) + .slider {
        background-color: $primary-color;
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 6px;

      &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: $light-color;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 6px;
      }

      &.round {
        border-radius: 34px;

        &:before {
          border-radius: 50%;
        }
      }
    }

    &.active {
      text-align: left;
      .text-status {
        margin-left: 10px;
        left: 0;

        &:nth-child(3) {
          opacity: 1;
        }
      }
    }

    &.inactive {
      text-align: right;
      .text-status {
        margin-right: 10px;
        right: 0;

        &:nth-child(4) {
          opacity: 1;
        }
      }
    }

    .text-status {
      color: $light-color;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
    }
  }
}

.main-sidebar {
  transition: width 0.3s ease-in-out;
}

.no-resize {
  resize: none;
}

.custom-checkbox-container,
.custom-radio-button {
  .label {
    font-size: 0.9rem;
    position: relative;
    padding-left: 0.5em;
    color: inherit;
    font-size: 1.2em;
    font-weight: normal;
  }

  .checkbox-container,
  .radio-btn-container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      background-color: $secondary-color;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    left: 0;
    top: 0;
    height: 20px;
    width: 20px;
  }
}

.filter {
  .custom-checkbox-container {
    margin-top: -1.5em;
  }
  .checkmark {
    top: calc(1em / 2);
  }
}

.custom-checkbox-container {
  .checkbox-container {
    padding-left: 2rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0;
  }

  input {
    height: 0;
    width: 0;
  }

  .checkmark {
    background-color: #eee;
    border-radius: 3px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 8px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  + .menu-text {
    margin-left: 0.25em;
  }
}

.custom-radio-button {
  display: flex;
  justify-content: flex-end;

  .radio-btn-container {
    padding-left: 2rem;
    margin-bottom: 0;
    margin-left: 1rem;
  }

  .checkmark {
    top: 0;
    background-color: $light-color;
    border-radius: 50%;
    border: 1px solid #b4bfc9;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);

    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $light-color;
    }
  }
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 0;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;

  .dot-stretching {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $light-color;
    color: $light-color;
    transform: scale(1.25, 1.25);
    animation: dotStretching 2s infinite ease-in;

    &::before,
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: $light-color;
    }

    &:before {
      animation: dotStretchingBefore 2s infinite ease-in;
    }

    &:after {
      animation: dotStretchingAfter 2s infinite ease-in;
    }

    &.dark {
      &::before,
      &::after {
        background-color: $primary-color;
      }
      background-color: $primary-color;
      color: $primary-color;
    }
  }
}
.window-modal-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.8;
  background-color: #fff;
}

@keyframes dotStretching {
  0% {
    transform: scale(1.25, 1.25);
  }
  50%,
  60% {
    transform: scale(0.8, 0.8);
  }
  100% {
    transform: scale(1.25, 1.25);
  }
}

@keyframes dotStretchingBefore {
  0% {
    transform: translate(0) scale(0.7, 0.7);
    opacity: 0.6;
  }
  50%,
  60% {
    transform: translate(-20px) scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: translate(0) scale(0.7, 0.7);
    opacity: 0.6;
  }
}

@keyframes dotStretchingAfter {
  0% {
    transform: translate(0) scale(0.7, 0.7);
    opacity: 0.6;
  }
  50%,
  60% {
    transform: translate(20px) scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: translate(0) scale(0.7, 0.7);
    opacity: 0.6;
  }
}
