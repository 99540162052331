.alert-container {
  position: absolute;
  right: 1rem;
  top: 100px;
  z-index: 5;

  .alert {
    display: flex;
    position: relative;
    align-items: center;
    padding: 1rem;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 1rem;
    min-width: 17rem;

    &-success {
      background-color: $success-color;
      color: $light-color;

      button {
        color: $light-color;
      }
    }

    button {
      padding: 0;
      margin-left: 10px;
    }

    .icon {
      margin-right: 10px;
      background-color: #06C371;
      // padding: 5px;
      width: 30px;
      height: 30px;
      text-align: center;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .message {
      flex: 2;
    }

    &.show {
      
    }
  }

  

 
  
}

